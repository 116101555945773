import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">Ⓒ2024 Big Eye Technology</p>
    </div>
  );
}

export default Footer;
