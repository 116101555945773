import React, { useEffect, useState } from "react";
import axios from "axios";
import funny from "../pics/funny.png.png";
import Footer from "../footer/Footer";
import "../jokes/Stylejoke.css";

// const url = "https://official-joke-api.appspot.com/jokes/random";
// const url = "https://official-joke-api.appspot.com/jokes/random";
const url = "https://official-joke-api.appspot.com/jokes/general/random";
// const url = "https://official-joke-api.appspot.com/jokes/ten";

function Funnyjokes() {
  const [jokes, setJokes] = useState([]);

  const fetchedJokes = async () => {
    await axios(url)
      .then((res) => setJokes(res.data))
      // .then((res) => console.log(res.data))
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchedJokes();
  }, []);

  const handleJokes = () => {
    fetchedJokes();
  };

  return (
    <div className="container-joke">
      <div className="header-joke">
        <h3 className="header-text">Big Eye Laughter</h3>
        <img src={funny} alt="name" className="logo" />
      </div>
      <div className="nav">
        <h5>
          www.jobdeyforyou.com is launching soon... for more information E-mail
          : bigeyetechnology@gmail.com{" "}
        </h5>
      </div>
      <div className="container_body">
        {jokes.map((user) => {
          const { id, punchline, setup } = user;
          return (
            <div className="content">
              <p key={id}>
                <label>Joke No. {id}</label>
              </p>

              <br />
              <label>**JOKE** </label>
              <p> {setup}</p>
              <br />
              <br />
              <label>**READY TO LAUGH** </label>
              <p> {punchline}</p>
              <div className="content-btn">
                <button className="content-btn" onClick={handleJokes}>
                  Generate Funny Joke
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <Footer />
    </div>
  );
}

export default Funnyjokes;
